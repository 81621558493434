import { Button, Typography } from 'antd'
import classNames from 'classnames'
import logo from 'assets/icons/mobile-logo.svg'
import qrAppDownload from 'assets/icons/qr-app-download.svg'
import styles from './RedirectToProduct.module.scss'

export const RedirectToProduct: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={logo} alt='Логотип компании' className={styles.logo} />
        <Typography.Title level={2} className={styles.title}>
          С вами поделились товаром на HorseSmart
        </Typography.Title>
        <Typography.Title level={4} className={styles.subTitle}>
          Для просмотра необходимо установить приложение:
        </Typography.Title>
        <div className={styles.body}>
          <div className={styles.qrWrapper}>
            <img
              src={qrAppDownload}
              alt='qr код для скачивания мобильного приложения'
              className={styles.qrImage}
            />
            <Typography.Text className={classNames(styles.descriptionText, styles.qrText)}>
              Сканируйте QR-код <br /> через камеру телефона
            </Typography.Text>
          </div>
          <div className={styles.line} />
          <div>
            <Button htmlType='button' download href={process.env.REACT_APP_APK_APP_URL} type='primary' size='large' className={styles.downloadApkButton}>
              Скачать APK
            </Button>
            <Button htmlType='button' href={process.env.REACT_APP_DOWNLOAD_IOS_APP_LINK} target='_blank' type='primary' size='large' style={{ marginBottom: 10 }} className={styles.downloadButton}>
              Скачать в App Store
            </Button>
            <Button htmlType='button' href={process.env.REACT_APP_DOWNLOAD_ANDROID_APP_LINK} target='_blank' type='primary' size='large' className={styles.downloadButton}>
              Скачать в Google Play
            </Button>
            <Typography.Text className={classNames(styles.descriptionText, styles.googleDownloadText)}>
              Если у вас не работает скачивание через Google Play, попробуйте включить VPN или скачать APK 
            </Typography.Text>
          </div>
        </div>
        <div className={styles.footer}>
          <Typography.Link href='tel:+79266893011' className={styles.footerLink}>+7 (926) 689 30-11</Typography.Link>
          <div className={styles.footerLine} />
          <Typography.Link href='mailto:info@horsesmart.ru' className={styles.footerLink}>info@horsesmart.ru</Typography.Link>
        </div>
      </div>
    </div>
  )
}
