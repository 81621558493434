import { Navigate, createBrowserRouter } from 'react-router-dom'
import { AuthWrapper } from 'components/layout/AuthWrapper/AuthWrapper'
import { Start } from 'pages/auth/Start/Start'
import { SignUp } from 'pages/auth/SignUp/SignUp'
import { RecoveryPassword } from 'pages/auth/RecoveryPassword/RecoveryPassword'
import { routes } from 'shared/constants/routes'
import { RedirectToProduct } from 'pages/mobileApp/RedirectToProduct'

export const authRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthWrapper />,
    children: [
      {
        index: true,
        element: <Start />
      },
      {
        path: routes.SIGN_UP,
        element: <SignUp />
      },
      {
        path: routes.RECOVERY_PASSWORD,
        element: <RecoveryPassword />
      },
      {
        path: '*',
        element: <Navigate to='/' />
      }
    ]
  },
  {
    path: routes.MOBILE_APP_PRODUCT,
    element: <RedirectToProduct />
  }
])
