export const routes = {
  SIGN_UP: '/registration',
  RECOVERY_PASSWORD: '/recovery-password',
  MOBILE_APP_PRODUCT: '/products/:id'
}

export const superAdminRoutes = {
  USERS: '/users',
  USERS_ADD: '/users/add',
  USERS_EDIT: '/users/edit/:id',
  SELLERS: '/sellers',
  SELLERS_ADD: '/sellers/add',
  SELLER: '/sellers/:id',
  SELLER_INFO: '',
  SELLER_GOODS: 'goods',
  SELLER_ORDERS: 'orders',
  SELLER_SHOPS: 'shops',
  SELLER_DELIVERY: 'delivery',
  SELLER_ACCOUNTING: 'accounting',
  SELLER_API_MOYSKLAD: 'moysklad',
  SELLER_NOTIFICATIONS_TELEGRAM: 'notifications-telegram',
  ORDERS: '/orders',
  ORDER: '/orders/:id',
  SUPER_ORDER: '/orders/superOrder/:id',
  GOODS: '/goods',
  GOODS_EDIT: '/goods/edit/:id',
  REVIEWS: '/reviews',
  CATEGORIES: '/categories',
  ACTIONS: '/actions',
  ACTIONS_NOTIFICATIONS: 'notifications',
  MOBILE_APP_PRODUCT: '/products/:id',
  HORSES: '/horses'
}

export const adminRoutes = {
  GOODS: '/goods',
  GOODS_ADD: '/goods/add',
  GOODS_EDIT: '/goods/edit/:id',
  ORDERS: '/orders',
  ORDER: '/orders/:id',
  PROFILE: '/profile',
  PROFILE_INFO: 'info',
  PROFILE_SHOPS: 'shops',
  PROFILE_DELIVERY: 'delivery',
  PROFILE_ACCOUNTING: 'accounting',
  PROFILE_SUPPORT: 'support',
  PROFILE_API_MOYSKLAD: 'moysklad',
  PROFILE_NOTIFICATIONS_TELEGRAM: 'notifications-telegram',
  MOBILE_APP_PRODUCT: '/products/:id'
}
