import React from 'react'
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  Typography
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import {
  EUnit,
  ICreateProductVariation,
  ICreateUpdateVariationProduct,
  IFrontVariation,
  IFrontVariationProduct,
  IProductFormData,
  IProductResponse,
  IUpdateProduct
} from 'models/product'
import noProductImage from 'assets/images/no-image-product.png'
import { Uploader } from 'components/ui/Uploader/Uploader'
import { productApi } from 'api/product'
import { floatNumbersPattern, numbersPattern } from 'shared/utils/patterns'
import { showServerError } from 'shared/utils/showServerError'
import { IMAGES_URL } from 'shared/constants/urls'
import { productTags } from 'shared/constants/product'
import { getNormalizedPrice } from 'shared/utils/getNormalizedPrice'
import styles from '../ProductForm.module.scss'
import {
  getCreateVariationSubmitData,
  getEditVariationSubmitData
} from '../utils/getSubmitData'
import { SelectedVariation } from './SelectedVariation'

interface IVariantProductModalFormProps {
  parentFormData: IProductFormData
  data: ICreateUpdateVariationProduct
  sellerId: string
  onSelectSuccess: (
    product: IFrontVariationProduct,
    variationIndex: number
  ) => void
  openChooseProductModal: (
    variation: IFrontVariation,
    variationIndex: number
  ) => void
  onClose: () => void
}

export const VariantProductModalForm: React.FC<IVariantProductModalFormProps> =
  React.memo(
    ({ data, parentFormData, sellerId, onSelectSuccess, openChooseProductModal, onClose }) => {
      const { visible, variation, type, variationIndex } = data

      const [products, setProducts] = React.useState<IProductResponse[]>([])
      const [isLoading, setIsLoading] = React.useState(false)
      const [isSubmitting, setIsSubmitting] = React.useState(false)
      const [createUpdateForm] = Form.useForm()

      const isUpdate = type === 'update'

      const [deletedPhotos, setDeletedPhotos] = React.useState<string[]>([])

      const productId: string | undefined = Form.useWatch('id', {
        form: createUpdateForm,
        preserve: true
      })

      const photo =
        Form.useWatch('photo', {
          form: createUpdateForm,
          preserve: true
        }) ?? []

      const getProductNameFormParent = () => {
        if (variation?.firstCharacteristicValue && variation.secondCharacteristicValue) {
          return `(${variation.firstCharacteristicValue.toLocaleLowerCase()}, ${variation.secondCharacteristicValue.toLocaleLowerCase()})`
        }
        if (variation?.firstCharacteristicValue) {
          return `(${variation.firstCharacteristicValue.toLocaleLowerCase()})`
        }
        if (variation?.secondCharacteristicValue) {
          return `(${variation.secondCharacteristicValue.toLocaleLowerCase()})`
        }
      }

      React.useEffect(() => {
        if (!visible) {
          createUpdateForm.resetFields()
          setProducts([])
          setDeletedPhotos([])
        }
      }, [visible])

      React.useEffect(() => {
        if (variation?.product && isUpdate) {
          const getProductData = async () => {
            try {
              setIsLoading(true)
              const { data } = await productApi.getProduct(
                variation?.product?.id as string
              )
              setProducts([data])
              createUpdateForm.setFields([
                { name: 'id', value: data._id },
                { name: 'seller', value: data.seller_id },
                { name: 'name', value: data.name },
                { name: 'vendor_code', value: data.vendor_code },
                { name: 'price', value: data.price },
                { name: 'tax', value: data.tax },
                { name: 'width', value: data?.dimension?.width },
                { name: 'length', value: data?.dimension?.length },
                { name: 'height', value: data?.dimension?.height },
                { name: 'weight', value: data?.dimension?.weight },
                { name: 'unit', value: data?.dimension?.unit },
                { name: 'in_stock', value: data?.dimension?.in_stock },
                {
                  name: 'photo',
                  value: data?.photo?.[0]
                    ? [
                      {
                        uid: data.photo[0],
                        thumbUrl: `${IMAGES_URL}${data.photo[0]}`
                      }
                    ]
                    : undefined
                },
                { name: 'desc', value: data.desc }
              ])
              if (data.photo && data.photo.length > 1) {
                setDeletedPhotos(data.photo.filter((_, index) => !!index))
              }
            } catch (e) {
              showServerError(e)
            } finally {
              setIsLoading(false)
            }
          }
          getProductData()
        }
      }, [variation])

      React.useEffect(() => {
        if (!isUpdate) {
          createUpdateForm.setFields([
            { name: 'id', value: parentFormData?.id },
            { name: 'seller', value: sellerId },
            { name: 'name', value: parentFormData?.name ? `${parentFormData.name} ${getProductNameFormParent()}` : undefined },
            { name: 'vendor_code', value: parentFormData?.vendor_code },
            { name: 'price', value: parentFormData?.price },
            { name: 'tax', value: parentFormData?.tax },
            { name: 'width', value: parentFormData?.width },
            { name: 'length', value: parentFormData?.length },
            { name: 'height', value: parentFormData?.height },
            { name: 'weight', value: parentFormData?.weight },
            { name: 'unit', value: parentFormData?.unit },
            { name: 'in_stock', value: parentFormData?.in_stock },
            {
              name: 'photo',
              value: parentFormData?.photo?.[0] ? [parentFormData?.photo?.[0]] : []
            },
            { name: 'desc', value: parentFormData?.desc }
          ])
        }
      }, [variation])

      const onSubmit = async () => {
        try {
          const data = await createUpdateForm.validateFields()
          setIsSubmitting(true)
          const normalizedData = isUpdate
            ? getEditVariationSubmitData(
              data,
              productId as string,
              deletedPhotos
            )
            : getCreateVariationSubmitData(data, sellerId)
          if (isUpdate) {
            const response = await productApi.updateProduct(
              normalizedData as IUpdateProduct
            )
            onSelectSuccess(
              { id: response.data._id, name: response.data.name },
              variationIndex as number
            )
          } else {
            const response = await productApi.createProductVariation(
              normalizedData as ICreateProductVariation
            )
            onSelectSuccess(
              { id: response.data._id, name: response.data.name },
              variationIndex as number
            )
          }
        } catch (e: any) {
          if (!e.errorFields) {
            showServerError(e)
          }
        } finally {
          setIsSubmitting(false)
        }
      }

      const columns: ColumnsType<IProductResponse> = React.useMemo(
        () => [
          {
            dataIndex: 'vendor_code',
            width: 101,
            align: 'center'
          },
          {
            dataIndex: 'photo',
            width: 70,
            align: 'center',
            render: (_, { photo }) => {
              return photo && !!photo.length ? (
                <div
                  style={{
                    width: 62,
                    height: 56,
                    backgroundImage: `url(${IMAGES_URL}${photo[0]})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
              ) : (
                <img
                  src={noProductImage}
                  alt='Изображение товара'
                  width={62}
                  height={56}
                />
              )
            }
          },
          {
            dataIndex: 'name',
            width: 282
          },
          {
            dataIndex: 'category_id',
            width: 282,
            render: (_, { category_id, subCategory_id }) => {
              if (!category_id || !subCategory_id) {
                return (
                  <Typography.Text style={{ opacity: 0.5 }}>
                    Не задана
                  </Typography.Text>
                )
              }
              return (
                <>
                  <Typography.Text>{category_id.name + ' > '}</Typography.Text>
                  <Typography.Text>{subCategory_id.name}</Typography.Text>
                </>
              )
            }
          },
          {
            dataIndex: 'price',
            width: 101,
            align: 'center',
            render: (_, { price }) => {
              return (
                <Typography.Text style={{ opacity: price ? 1 : 0.5 }}>
                  {price ? getNormalizedPrice(price) : 'Не задана'}
                </Typography.Text>
              )
            }
          },
          {
            dataIndex: 'status',
            width: 136,
            align: 'center',
            render: (_, { status }) => {
              return (
                <Tag color={productTags[status].color}>
                  {productTags[status].label}
                </Tag>
              )
            }
          },
          {
            title: '',
            dataIndex: '',
            width: 58,
            align: 'center',
            render: () => {
              return (
                <Button
                  htmlType='button'
                  type='primary'
                  size='middle'
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    openChooseProductModal(
                      variation as IFrontVariation,
                      variationIndex as number
                    )
                  }
                />
              )
            }
          }
        ],
        [products]
      )

      if (isLoading) {
        return null
      }

      return (
        <Modal
          centered
          open={visible}
          footer={null}
          closable={false}
          onCancel={onClose}
          width={1100}
        >
          <SelectedVariation
            title={isUpdate ? 'Товар для' : 'Создание товара для'}
            variation={variation as IFrontVariation}
            onClose={onClose}
          />
          {isUpdate && (
            <Table
              scroll={{
                x: 700
              }}
              size='small'
              columns={columns}
              showHeader={false}
              bordered={true}
              dataSource={products}
              rowKey={(el) => el._id}
              pagination={false}
              style={{ marginTop: 15, marginBottom: 30 }}

            />
          )}
          <Form
            form={createUpdateForm}
            name='createUpdateForm'
            size='middle'
            style={{ marginTop: isUpdate ? 20 : 17 }}
          >
            <div className={styles.createUpdateVariantProductFormContent}>
              <div
                className={styles.createUpdateVariantProductFormContentUploader}
              >
                <Uploader
                  name='photo'
                  type='variation'
                  disabled={!!photo.length}
                  rules={[]}
                  onRemoveClick={(file) => {
                    if (!file.originFileObj) {
                      setDeletedPhotos((prev) => [...prev, file?.uid ?? ''])
                    }
                  }}
                />
                <Typography.Text
                  className={styles.subTitle}
                  style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                >
                  • Вы можете загрузить только 1 фото вариации. <br />•
                  Рекомендованный размер изображений не менее 512px по ширине.{' '}
                  <br />• Поддерживаются форматы JPG, JPEG, PNG.{' '}
                </Typography.Text>
              </div>
              <div
                className={styles.createUpdateVariantProductFormContentFields}
              >
                <Form.Item
                  name='name'
                  label='Название товара'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните название товара'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните название товара'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Ваше название' />
                </Form.Item>
                <Form.Item
                  name='vendor_code'
                  label='Артикул'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните артикул товара'
                    },
                    {
                      whitespace: true,
                      message: 'Заполните артикул товара'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Ваш внутренний артикул' />
                </Form.Item>
                <div className={styles.flexBig} style={{ gap: 10 }}>
                  <Form.Item
                    name='price'
                    label='Стоимость'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните стоимость товара'
                      },
                      {
                        pattern: floatNumbersPattern,
                        message: 'Заполните стоимость товара корректно'
                      }
                    ]}
                    className={styles.inputFlex}
                    hasFeedback
                  >
                    <Input placeholder='Цена в рублях для покупателя' />
                  </Form.Item>
                  <Form.Item
                    name='tax'
                    rules={[
                      {
                        required: true,
                        message: 'Выберите размер налога'
                      }
                    ]}
                    style={{ width: 238, marginBottom: 0 }}
                    hasFeedback
                  >
                    <Select
                      placeholder='Размер налога'
                      options={[
                        { value: 10, label: '10%' },
                        { value: 20, label: '20%' },
                        { value: 0, label: 'Без налога' }
                      ]}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name='width'
                  label='Ширина'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните ширину упакованного товара'
                    },
                    {
                      pattern: numbersPattern,
                      message: 'Заполните ширину упакованного товара корректно'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Ширина упакованного товара в сантиметрах' />
                </Form.Item>
                <Form.Item
                  name='length'
                  label='Длина'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните длину упакованного товара'
                    },
                    {
                      pattern: numbersPattern,
                      message: 'Заполните длину упакованного товара корректно'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Длина упакованного товара в сантиметрах' />
                </Form.Item>
                <Form.Item
                  name='height'
                  label='Высота'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните высоту упакованного товара'
                    },
                    {
                      pattern: numbersPattern,
                      message: 'Заполните высоту упакованного товара корректно'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Высота упакованного товара в сантиметрах' />
                </Form.Item>
                <div className={styles.flexBig} style={{ gap: 10 }}>
                  <Form.Item
                    name='weight'
                    label='Вес'
                    rules={[
                      {
                        required: true,
                        message: 'Заполните вес товара'
                      },
                      {
                        pattern: floatNumbersPattern,
                        message: 'Заполните вес товара корректно'
                      }
                    ]}
                    className={styles.inputFlex}
                    hasFeedback
                  >
                    <Input placeholder='Общий вес товара' />
                  </Form.Item>
                  <Form.Item
                    name='unit'
                    rules={[
                      {
                        required: true,
                        message: 'Выберите единицы измерения'
                      }
                    ]}
                    style={{ width: 238, marginBottom: 0 }}
                    hasFeedback
                  >
                    <Select
                      placeholder='Единицы измерения'
                      options={[
                        { value: EUnit.GR, label: 'Граммы' },
                        { value: EUnit.KG, label: 'Килограммы' }
                      ]}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name='in_stock'
                  label='В наличии (шт.)'
                  rules={[
                    {
                      required: true,
                      message: 'Заполните количество товара в штуках'
                    },
                    {
                      pattern: numbersPattern,
                      message: 'Заполните количество товара в штуках корректно'
                    }
                  ]}
                  hasFeedback
                >
                  <Input placeholder='Количество товара в штуках' />
                </Form.Item>
                <Typography.Title
                  level={4}
                  className={styles.sectionSubTitle}
                  style={{ marginBottom: 16 }}
                >
                  Описание:
                </Typography.Title>
                <Form.Item name='desc' hasFeedback>
                  <Input.TextArea placeholder='Описание товара' rows={4} />
                </Form.Item>
              </div>
            </div>
            <div className={styles.chooseVariationProductFooter}>
              <Button
                type='default'
                size='middle'
                onClick={() =>
                  openChooseProductModal(
                    variation as IFrontVariation,
                    variationIndex as number
                  )
                }
              >
                {isUpdate
                  ? 'Выбрать другой товар'
                  : 'Выбрать уже созданный товар'}
              </Button>
              <Button
                type='primary'
                size='middle'
                loading={isSubmitting}
                onClick={onSubmit}
              >
                Сохранить и связать
              </Button>
            </div>
          </Form>
        </Modal>
      )
    }
  )
