import React from 'react'
import { Button, Checkbox, Form, Input, Typography, message } from 'antd'
import { ENotificationRecipients } from 'models/seller'
import { actionsApi } from 'api/actions'
import { showServerError } from 'shared/utils/showServerError'
import { showMessage } from 'shared/utils/showMessage'
import styles from './Notifications.module.scss'

interface IFormValues {
  sendToUsers: boolean
  sendToSellers: boolean
  title: string
  message: string
}

export const ActionsNotifications: React.FC = () => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = React.useState(false)

  const handleFormSubmit = async (data: IFormValues) => {
    try {
      if (!data.sendToUsers && !data.sendToSellers) {
        return showMessage('error', 'Выберите получателей уведомления', '')
      }
      setIsLoading(true)
      const recipients: ENotificationRecipients =
        data.sendToUsers && data.sendToSellers
          ? ENotificationRecipients.ALL
          : data.sendToUsers
            ? ENotificationRecipients.USERS
            : ENotificationRecipients.SELLERS
      await actionsApi.sendNotifications({
        to: recipients,
        notificationData: {
          title: data.title.trim(),
          body: data.message.trim()
        }
      })
      form.resetFields()
      message.success('Уведомление успешно отправлено!')
    } catch (e) {
      showServerError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      initialValues={{ sendToUsers: true, sendToSellers: true }}
    >
      <Typography.Title className={styles.title} level={2}>
        Отправка уведомлений
      </Typography.Title>
      <Form.Item
        name='sendToUsers'
        className={styles.boxBorder}
        valuePropName='checked'
      >
        <Checkbox>Всем пользователям</Checkbox>
      </Form.Item>
      <Form.Item
        name='sendToSellers'
        className={styles.boxBorder}
        valuePropName='checked'
      >
        <Checkbox>Всем продавцам</Checkbox>
      </Form.Item>
      <Typography.Title level={4} className={styles.descriptionTitle}>
        Описание уведомлений:
      </Typography.Title>
      <Form.Item
        name='title'
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: true,
            message: 'Заполните заголовок уведомления'
          },
          {
            whitespace: true,
            message: 'Заполните заголовок уведомления'
          }
        ]}
      >
        <Input placeholder='Заголовок уведомления' size='large' />
      </Form.Item>
      <Form.Item
        name='message'
        style={{ marginBottom: 15 }}
        rules={[
          {
            required: true,
            message: 'Заполните текст уведомления'
          },
          {
            whitespace: true,
            message: 'Заполните текст уведомления'
          }
        ]}
      >
        <Input.TextArea
          placeholder='Текст уведомления'
          size='large'
        />
      </Form.Item>
      <Button type='primary' htmlType='submit' size='large' loading={isLoading}>
        Отправить
      </Button>
    </Form>
  )
}
