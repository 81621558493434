import React from 'react'
import { Button, Typography } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { SignIn } from 'components/auth/SignIn/SignIn'
import { SellerInfo } from 'components/auth/SignUp/SellerInfo/SellerInfo'
import { CreatePassword } from 'components/auth/SignUp/CreatePassword/CreatePassword'
import googlePlayIcon from 'assets/icons/Google-Play.svg'
import appStoreIcon from 'assets/icons/App-Store.svg'
import { routes } from 'shared/constants/routes'
import styles from './Start.module.scss'

export type TAuthScreen = 'password' | 'seller' | 'default'

export const Start: React.FC = () => {
  const [activeScreen, setActiveScreen] = React.useState<TAuthScreen>('default')
  const [isEmptyPassword, setIsEmptyPassword] = React.useState(false)
  const [token, setToken] = React.useState('')

  const setDefaultScreen = React.useCallback(
    () => setActiveScreen('default'),
    []
  )
  const setPasswordScreen = React.useCallback(
    () => setActiveScreen('password'),
    []
  )
  const setSellerScreen = React.useCallback(() => setActiveScreen('seller'), [])

  if (activeScreen === 'password') {
    return (
      <CreatePassword
        token={token}
        onSuccess={setSellerScreen}
        onGoBack={setDefaultScreen}
      />
    )
  }

  if (activeScreen === 'seller') {
    return (
      <SellerInfo
        token={token}
        onSuccess={setDefaultScreen}
        onGoBack={isEmptyPassword ? undefined : setDefaultScreen}
      />
    )
  }

  return (
    <div className={styles.content}>
      <div className={styles.signUp}>
        <Typography.Title className={styles.title}>
          Регистрация
        </Typography.Title>
        <Typography.Paragraph>
          Вы можете пройти регистрацию через веб интерфейс:
        </Typography.Paragraph>
        <Link to={routes.SIGN_UP}>
          <Button
            block
            type='primary'
            size='large'
            icon={<FormOutlined />}
            className={styles.signUpButton}
          >
            Зарегистрироваться
          </Button>
        </Link>
        <Typography.Paragraph>
          Или зарегистрироваться как продавец через наше мобильное приложение:
        </Typography.Paragraph>
        <a
          href={process.env.REACT_APP_DOWNLOAD_ANDROID_APP_LINK}
          rel='noreferrer'
          target='_blank'
          className={styles.appLink}
          style={{ marginBottom: 11 }}
        >
          <img src={googlePlayIcon} alt='Ссылка на приложение в Google Play' />
        </a>
        <a
          href={process.env.REACT_APP_DOWNLOAD_IOS_APP_LINK}
          rel='noreferrer'
          target='_blank'
          className={styles.appLink}
        >
          <img src={appStoreIcon} alt='Ссылка на приложение в App Store' />
        </a>
      </div>
      <div className={styles.signIn}>
        <SignIn
          setActiveScreenPassword={setPasswordScreen}
          setActiveScreenSeller={setSellerScreen}
          setToken={setToken}
          setIsEmptyPassword={setIsEmptyPassword}
        />
      </div>
    </div>
  )
}
